'use strict';
var $ = require('../internals/export');
var toString = require('../internals/to-string');

var raw = /[\w*+\-./@]/;

var hex = function (code, length) {
  var result = code.toString(16);
  while (result.length < length) result = '0' + result;
  return result;
};

// `escape` method
// https://tc39.es/ecma262/#sec-escape-string
$({ global: true }, {
  escape: function escape(string) {
    var str = toString(string);
    var result = '';
    var length = str.length;
    var index = 0;
    var chr, code;
    while (index < length) {
      chr = str.charAt(index++);
      if (raw.test(chr)) {
        result += chr;
      } else {
        code = chr.charCodeAt(0);
        if (code < 256) {
          result += '%' + hex(code, 2);
        } else {
          result += '%u' + hex(code, 4).toUpperCase();
        }
      }
    } return result;
  }
});
